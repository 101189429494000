<template>
  <div id="help" class="contenedor text-center">
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center" style="background: #fefefe">
      <v-container fluid class="base text-center" style="background: #fefefe">
        <v-row
          no-gutters
          justify="center"
          align="center"
          align-content="center"
          class="pa-0 justify-center"
          style="height: auto !important; width: 100% !important"
        >
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            align-self="center"
            class="justify-center"
          >
            <section class="help2">
              <v-avatar size="60" class="pa-0">
                <v-img src="../../assets/logotipo.png" alt="Bool" class="ml-n1">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="orange"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img> </v-avatar
              ><br />
              <label style="font-size:2em !important">¿En qué podemos ayudarte?</label><br />
              <v-autocomplete
                v-model="select"
                :loading="loading"
                :items="entries"
                :search-input.sync="search"
                style="width:60%; margin-left:20%"
                flat
                hide-no-data
                hide-details
                label="Buscar en la ayuda"
                dense
                item-text="titulo"
                item-value="id"
                clearable
                @click:clear="limpiar"
                :filter="filtro"
                color="orange"
                prepend-icon="search"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.titulo }}
                </template>
              </v-autocomplete>
            </section>
          </v-col>
          <v-row align-content="center" justify="center">
            <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              align-self="end"
              :class="`d-flex justify-center`"
            >
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-expansion-panels popout>
            <v-expansion-panel v-for="(item, i) in ayuda" :key="i">
              <v-expansion-panel-header style="color: grey">
                <h3>{{ item.nombreSeccion }}</h3></v-expansion-panel-header
              >
              <v-expansion-panel-content
                class="text-justify"
                v-if="item.secciones.length == 0"
              >
                {{ item.contenido }}
              </v-expansion-panel-content>
              <v-expansion-panel-content
                class="text-justify"
                v-if="item.secciones.length > 0"
              >
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(seccion, index) in item.secciones"
                    :key="index"
                    @click="especifica(seccion.id)"
                  >
                    <v-expansion-panel-header
                      style="color: grey; font-weigth: bold"
                      >{{ seccion.titulo }}
                    </v-expansion-panel-header>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Sesion,
  },
  data() {
    return {
      expiration: false,
      search: null,
      entries: [],
      loading: false,
      select: null,
      ayuda: [],
    };
  },
  methods: {
    /*Aplica un filtro por el cual se visualizan las secciones de ayuda */
    filtro (item, queryText, itemText) {
      const textOne = item.contenido.toLowerCase()
      const textTwo = item.titulo.toLowerCase()
      const searchText = queryText.toLowerCase()
     return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    /*Va realizando la busqueda de ayuda acorde a la coincidencia que el usuario va ingresando*/
    querySelections(v) {
        this.loading = true;
        setTimeout(
          () =>
            axios
              .get(Server + "/ayuda/busqueda-secciones/1?termino=" + v)
              .then((response) => {
                response.data.forEach((value) => {
                  if (value.titulo != null) {
                    this.entries.push(value);
                  }
                });
                //this.entries = response.data;
                console.log("coincidencia",this.entries);
                this.loading = false;
                if (typeof this.select === "number") {
                  localStorage.idAyuda = this.select;
                  this.$router.push("/specific");
                } else {
                  this.especifico = false;
                }
              })
              .catch((e) => {
                //console.log(e);
              }),
          500
        );
    },
    limpiar() {
      this.select = null;
      this.especifico = false;
    },
    especifica(item) {
      localStorage.idAyuda = item;
      console.log("para especifica", item);
      this.$router.push("/specific");
    },
    obtenerAyuda() {
      this.show = true;
      axios
        .get(Server + "/ayuda/secciones/1", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.ayuda = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log("Error", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },

  watch: {
    search(val) {
      if (this.select != null || this.select != "") {
        this.tamanio = 120;
      }
      else {
        this.tamanio = 500;
      }
      val && val !== this.select && this.querySelections(val);
    },
  },
  created() {
    this.obtenerAyuda();
  },
};
</script>